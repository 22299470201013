<template>
  <v-col
    cols="12"
    md="12"
  >
    <base-material-card
      color="warning"
      class="px-5 py-3"
    >
      <template v-slot:heading>
        <div class="text-h3 font-weight-light">Подробности заказа</div>

        <div class="text-subtitle-1 font-weight-light">
          <h5>Общее количество: {{ ordersList.length }}</h5>
        </div>
      </template>
      <v-card-text>
        <v-data-table
          dense
          :expanded.sync="expanded"
          item-key="name.last"
          :headers="headers"
          :items="ordersList"
          :loading="loading"
          group-by="vendor"
        >
        </v-data-table>
        <div class="orderInfoWrapper">
          <div
            class="userNameW"
            style="display: flex"
            v-if="userName"
          >
            Имя клиента :
            <p style="color: green; font-weight: 500">
              {{ userName }}
            </p>
          </div>
          <div
            class="promo"
            style="display: flex"
            v-if="orderInfo.promoData"
          >
            Введенный промокод :
            <p style="color: green; font-weight: 500">
              {{ orderInfo?.promoData?.name }}
            </p>
          </div>
          <div
            v-if="orderInfo.promoData"
            style="display: flex"
          >
            Дата применения :
            <p style="color: green; font-weight: 500">
              {{ dateFormatter(orderInfo?.promoData?.created_at) }}
            </p>
          </div>
          <div
            v-if="orderInfo.promoData"
            style="display: flex"
          >
            Скидка :
            <p style="color: green; font-weight: 500">{{ orderInfo?.promoData?.sale }}%</p>
          </div>
          <div v-if="orderInfo">
            <div
              class="typePay"
              style="display: flex"
              v-if="orderInfo.typePayData === 1"
            >
              Тип оплаты :
              <p style="color: green; font-weight: 500">Онлайн</p>
            </div>
            <div
              class="typePay"
              style="display: flex"
              v-else
            >
              Типо оплаты :
              <p style="color: green; font-weight: 500">Наличными</p>
            </div>
          </div>
        </div>
      </v-card-text>
    </base-material-card>
    <router-link to="/pages/orders"><p>Назад</p></router-link>
  </v-col>
</template>

<script>
import OrdersService from '@/app/Services/OrdersService'
import TableDialogMixin from '@/app/Helpers/mixins/BaseTableDialog'
import dateFormatter from '@/app/Helpers/dateFormatter'
import GetClients from '@/app/Helpers/mixins/essences/getClients'
import GetGoods from '@/app/Helpers/mixins/essences/getGoods'
import GetUsers from '@/app/Helpers/mixins/essences/getUsers'

export default {
  name: 'OrdersDashboard',

  mixins: [TableDialogMixin, GetClients, GetGoods, GetUsers],

  data() {
    return {
      userName: '',
      orderInfo: '',
      dialogCourier: false,
      courierData: {},
      ordersList: [],
      // selectedExtras:'',

      headers: [
        {
          sortable: true,
          text: 'ID',
          value: 'id',
        },
        {
          sortable: false,
          text: 'Название',
          value: 'name',
          align: 'left',
        },
        {
          sortable: true,
          text: 'Сумма заказа',
          value: 'price',
          align: 'left',
        },
        {
          sortable: true,
          text: 'Количество',
          value: 'quantity',
        },
      ],
    }
  },
  async created() {
    this.loading = true
    await this.getOrderById()
    this.loading = false
  },
  methods: {
    dateFormatter,
    async getOrderById() {
      const response = await OrdersService.getOrderById(this.$attrs?.id)

      if (response.status) {
        this.ordersList = response.data?.orders
        this.ordersList.forEach((e) => {
          e.selectedExtras.forEach((h) => {
            this.ordersList.push({ vendor: e.name, name: h.name, price: h.price, id: h.id })
          })
        })
        let orderData = {
          promoData: response.data.promocode,
          typePayData: response.data.online_payment,
        }
        this.orderInfo = orderData
        this.userName = response.data.name
        this.$store.dispatch('orders/setOrdersData', response.data)
      }
    },
  },
  mounted() {
    this.$attrs?.id
    // for (let i = 0; i <= this.headers.length; i++) {
    //   if (this.headers[i].value === 'Дополнения') {
    //     // this.headers[i].value
    //     this.ordersList.forEach((e) => {
    //       e.selectedExtras.forEach((h) => {
    //         this.headers[i].value = h.name
    //       })
    //     })
    //   }
    // }
  },
}
</script>
